<template>
    <div id="pharmacy-list">

        <b-modal centered size="sm" id="modal-prevent-closing" ref="matching-modal" hide-footer :title="name">
            <b-form-input placeholder="Insérer le code de l'officine correspondant" ref="pharmacyCode-input"
                v-model="pharmacyCode" type="text" @keyup.enter="handleOk"></b-form-input>
            <div class="d-block text-center">
                <b-button variant="relief-success" class="mt-1" @click="handleOk">Valider</b-button>
            </div>
        </b-modal>

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des fournisseurs" @refresh="loadData" />
            </b-card>

        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue";
import BadgeCellRenderer from '@/views/components/ag-grid/BadgeCellRenderer.vue';
// import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            name: "",
            pharmacyCode: "",
            customer: null,
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "No",
                        field: "supplierNo",
                        width: 90,
                    },
                    {
                        headerName: "Nom Complet",
                        field: "fullName",
                    },
                    {
                        headerName: "Tel",
                        field: "phoneNo",
                        width: 90,
                    },
                    {
                        headerName: "Adresse",
                        field: "address",
                    },
                    {
                        headerName: "E-mail",
                        field: "email",
                    },
                    {
                        headerName: "Crée le",
                        field: "createdAt",
                    },
                    {
                        headerName: "Affecté le",
                        field: "affectedAt",
                    },
                    {
                        headerName: "Statut",
                        field: "affected",
                        cellRendererFramework: 'badgeCellRenderer',
                        cellRendererParams: { componentName: "CustomerByCompany", onButtonClick: this.onButtonClick },
                        width: 100
                    },
                ],
                rows: []
            },
        }
    },
    created() {
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.loadData();
    },
    methods: {
        async handleOk() {
            this.$refs['matching-modal'].hide();
            this.showLoading = true;
            let obj = {
                companyId: this.customer.company,
                supplierId: this.customer.id,
                supplierNo: this.customer.supplierNo,
                vendorNo: this.pharmacyCode,
            }
            let response = await this.$http.post("_vendor", obj).catch(() => {
                this.showLoading = false;
            });
            this.agGrid.rows = response.data;
            this.showLoading = false
        },
        onButtonClick(data) {
            this.pharmacyCode = "";
            this.customer = data;
            this.name = data.fullName;
            this.$refs['matching-modal'].show();
            setTimeout(() => {
                this.$refs["pharmacyCode-input"].focus();
            }, 400);
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("supplier/company/" + this.$route.params.slug).catch(() => {
                this.showLoading = false;
            });
            this.agGrid.rows = response.data;
            this.showLoading = false;
        }
    }
}
</script>

<style lang="scss"></style>
